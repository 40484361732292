@import url('https://fonts.googleapis.com/css2?family=Hedvig+Letters+Serif:opsz@12..24&family=Poppins:wght@100&display=swap');
.footerlogo{
    height: 40px;
    width: 60px;
}
.ankerfooter{
    text-decoration: none;
}
.develop{
    color: orange;
}
