@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');


*{
  font-family: "Roboto Slab", serif;

}


.wrapper {
  text-align: center;
  background-color: #000000;

}

.box{
  background-color: #000000 !important;
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 25px;
  background: #f3f5f9;
  margin: 20px auto;
}

.wrapper .row {
  justify-content: center;
}

.wrapper .feature {
  padding: 10px;
  border-radius: 11px !important;

  border: 4px solid #000000;
}

.wrapper .feature h3 {
  font-size: 18px;
  font-weight: 500;
  width: fit-content;
  margin: 0 auto !important;
}

.wrapper p {
  font-size: 15px;
  margin-top: 20px;
}

produ{
  background-color: aqua !important;
}






.paymentWrap {
	padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.paymentWrap .paymentBtnGroup {
	max-width: 800px;
	margin: auto;
}

.paymentWrap .paymentBtnGroup .paymentMethod {
	padding: 40px;
	box-shadow: none;
	position: relative;
  width: 30px;
}

.paymentWrap .paymentBtnGroup .paymentMethod.active {
	outline: none !important;
}

.paymentWrap .paymentBtnGroup .paymentMethod.active .method {
	border-color: black;
	outline: none !important;
	box-shadow: 0px 3px 22px 0px #7b7b7b;
}

.paymentWrap .paymentBtnGroup .paymentMethod .method {
	position: absolute;
	right: 3px;
	top: 3px;
	bottom: 3px;
	left: 3px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	border: 2px solid transparent;
	transition: all 0.5s;
}

.paymentWrap .paymentBtnGroup .paymentMethod .method.visa {
	background-image: url("https://seeklogo.com/images/P/perfect-money-logo-E9115506CD-seeklogo.com.png");
  border-radius: 50% !important;
box-shadow:  5px 5px 10px #696969,
             -5px -5px 10px #ffffff;
             width: 75px; 
             height: 75px; 
             margin-top: 12px;
}


.paymentWrap .paymentBtnGroup .paymentMethod .method.crypto {
  background-image: url("https://icons.veryicon.com/png/o/business/work-circle/cryptocurrency-wallet.png");
  border-radius: 50% !important;
  width: 75px; 
  height: 75px; 
  margin-top: 12px;
  border-radius: 51px;
box-shadow:  5px 5px 10px #696969,
             -5px -5px 10px #ffffff;
}





/* .paymentWrap .paymentBtnGroup .paymentMethod .method:hover {
	border-color: black;
	outline: none !important;
} */

.btnu{
  margin-top: -39px !important;
}


.containera {
  max-width: 800px; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Center the containera */
  padding: 20px;
}

.car{
  font-family: sans-serif;
  border: solid;
  border-radius: 15px;
  border-color: #9c8fe7;
  background-color: #ffffff;
  margin-bottom: 100px !important;
}

/* Add this to your existing CSS or create a new file */


.coupon-section {
  margin-top: 10px;
  display: flex;
  align-items: center;
}



.apply-button {
  padding: 7px 10px;

  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 17px;
}

.cart-list{
  border-radius: 15px;
  background: #e0e0e0;
  border: solid;
  border-color: #cecbcb;
}




.whatsapp-container {
  position: fixed;
  bottom: 20px;
  left: 20px; /* Change right to left */
}

.whatsapp-link {
  display: block;
  width: 53px;
  height: 53px;
  background-color: #25d366; /* Set your desired background color */
  border-radius: 50%; /* Make it round */
  text-align: center;
  line-height: 53px; /* Center the icon vertically */
  color: white; /* Set text color */
  text-decoration: none; /* Remove default underline */
}

.whatsapp-link:hover {
  background-color: #128c7e; /* Change background color on hover */
}



.poffbtn {
  font-size: 18px;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  padding: 0.4em 1em;
  border: 3px solid #df2e37;
  border-radius: 2px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
  color: #df2e37;
  width: 170px !important;
  text-decoration: none;
  transition: 0.3s ease all;
  z-index: 1;
}

.poffbtn:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: '';
  background-color: #df2e37;
  z-index: -1;
}

.poffbtn:hover, .poffbtn:focus {
  color: white;
}

.poffbtn:hover:before, .poffbtn:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.poffbtn:active {
  transform: scale(0.9);
}