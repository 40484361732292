.back{
    background-image: url('https://storage.googleapis.com/devitary-image-host.appspot.com/15848031292911696601-undraw_designer_life_w96d.svg');
    margin-top: -120px;
}

.fiv{
    margin-top: 30px;
    background-color: transparent;
}

.useremail{
    margin-top: -15px;
}

.ack{
    background-color:red;
}